import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BusinessDataState } from '../interfaces/appInterface'

const initialState: BusinessDataState = {
  businessData: null
}

const businessDataSlice = createSlice({
  name: 'businessData',
  initialState,
  reducers: {
    setBusinessData(state: BusinessDataState, action: PayloadAction<any>) {
      state.businessData = action.payload
    }
  }
})

export const { setBusinessData } = businessDataSlice.actions
export default businessDataSlice.reducer
