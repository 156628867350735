"use client"
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './global.css'
import ReduxProvider from './app-store/provider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import Script from 'next/script'
import { Unbounded } from 'next/font/google'


const unbounded = Unbounded({
  weight: ['300', '400', '700'],
  subsets: ['latin'],
  display: 'swap'
})

export default function RootLayout({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <html
      lang="en"
      className={unbounded.className}
      style={{ fontFamily: unbounded.style.fontFamily }}
    >
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Kegow Business App</title>
        <meta name="description" content="Easy and seamless payments" />
        <link rel="icon" href="/favicon.ico" />
      </head>
      <body
        className={unbounded.className}
        style={{ fontFamily: unbounded.style.fontFamily }}
      >
        <ReduxProvider>
          {children}
          <ToastContainer />
        </ReduxProvider>

        {/*JAVASCRIPT FILES */}
        <Script src="/js/jquery.min.js" strategy="lazyOnload" />
        <Script src="/js/bootstrap.bundle.min.js" strategy="lazyOnload" />
        {/* <Script src="/js/custom.js" strategy="lazyOnload" async /> */}
      </body>
    </html>
  )
}
