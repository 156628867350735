import { configureStore } from '@reduxjs/toolkit'
import businessData from './businessDataSlice'
import nav from './navSlice'

const store = configureStore({
  reducer: {
    businessData,
    nav
  }
})

export default store
